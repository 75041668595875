import { useEffect, useState } from 'react';
import Editor from './components/Editor';
import { loadFromBytebin } from './util/storage';

const INITIAL = Symbol();
const LOADING = Symbol();
const LOADED = Symbol();

type LoadingState = typeof INITIAL | typeof LOADING | typeof LOADED;

export default function App() {
  const [pasteId] = useState<string | undefined>(getPasteIdFromUrl);
  const [state, setState] = useState<LoadingState>(INITIAL);
  const [forcedContent, setForcedContent] = useState<string>('');
  const [actualContent, setActualContent] = useState<string>('');
  const [contentType, setContentType] = useState<string>();

  function setContent(content: string) {
    setActualContent(content);
    setForcedContent(content);
  }

  useEffect(() => {
    if (pasteId && state === INITIAL) {
      setState(LOADING);
      setContent('Загрузка...');

      loadFromBytebin(pasteId).then(({ ok, content, type }) => {
        if (ok) {
          setContent(content);
          if (type) {
            setContentType(type);
          }
        } else {
          setContent(get404Message(pasteId));
        }
        setState(LOADED);
      });
    }
  }, [pasteId, state]);

  return (
    <Editor
      forcedContent={forcedContent}
      actualContent={actualContent}
      setActualContent={setActualContent}
      contentType={contentType}
      pasteId={pasteId}
    />
  );
}

function get404Message(pasteId: string) {
  return `      d8888   .d8888b.      d8888  
     d8P888  d88P  Y88b    d8P888  
    d8P 888  888    888   d8P 888  
   d8P  888  888    888  d8P  888  
  d88   888  888    888 d88   888  
  8888888888 888    888 8888888888 
        888  Y88b  d88P       888  
        888   "Y8888P"        888  


  упс... запись '${pasteId}'
  не найдена. возможно, вы ошиблись?
`;
}

function getPasteIdFromUrl() {
  const path = window.location.pathname;
  if (path && /^\/[a-zA-Z0-9]+$/.test(path)) {
    return path.substring(1);
  } else {
    return undefined;
  }
}
